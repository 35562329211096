<template>
  <!-- 快速打开【待我审批】【我发起的】【我已审批】【被撤销的】 -->
  <div class="applyContainer van-page-bg-color global-div">
    <div class="applyNavBar">
      <van-grid :width="750" :column-num="4">
        <van-grid-item
          :padding="false"
          :borderRight="true"
          @click="jump2('dwsp', '待我审批')"
        >
          <template #icon>
            <van-badge
              :contentWidth="77"
              :contentHeight="77"
              :content="forcheckcount ? forcheckcount : null"
              style="text-align: center"
              :show-zero="false"
            >
              <van-image
                :src="require('@assets/img/dwsp2019.png')"
                :scale="1"
                :width="'75%'"
              ></van-image>
            </van-badge>
          </template>
          <template #text>
            <span
              class="van-grid-item__text"
              style="font-size: 14px; text-align: center; color: #000"
              >{{ $t('apply.appText1') }}</span
            >
          </template>
        </van-grid-item>
        <van-grid-item
          :padding="false"
          :borderRight="true"
          @click="jump2('wfqd', '我发起的')"
        >
          <template #icon>
            <van-badge
              :contentWidth="77"
              :contentHeight="77"
              :num="0"
              style="text-align: center"
            >
              <van-image
                :src="require('@assets/img/wfqd2019.png')"
                :scale="1"
                :width="'75%'"
              ></van-image>
            </van-badge>
          </template>
          <template #text>
            <span
              class="van-grid-item__text"
              style="font-size: 14px; text-align: center; color: #000"
              >{{ $t('apply.appText2') }}</span
            >
          </template>
        </van-grid-item>
        <van-grid-item
          :padding="false"
          :borderRight="true"
          @click="jump2('wysp', '我已审批')"
        >
          <template #icon>
            <van-badge
              :contentWidth="77"
              :contentHeight="77"
              :num="0"
              style="text-align: center"
            >
              <van-image
                :src="require('@assets/img/wysp2019.png')"
                :scale="1"
                :width="'75%'"
              ></van-image>
            </van-badge>
          </template>
          <template #text>
            <span
              class="van-grid-item__text"
              style="font-size: 14px; text-align: center; color: #000"
              >{{ $t('apply.appText3') }}</span
            >
          </template>
        </van-grid-item>
        <van-grid-item :padding="false" @click="jump2('bcxd', '被撤销的')">
          <template #icon>
            <van-badge
              :contentWidth="77"
              :contentHeight="77"
              :num="0"
              style="text-align: center"
            >
              <van-image
                :src="require('@assets/img/bcxd2019.png')"
                :scale="1"
                :width="'75%'"
              ></van-image>
            </van-badge>
          </template>
          <template #text>
            <span
              class="van-grid-item__text"
              style="font-size: 14px; text-align: center; color: #000"
              >{{ $t('apply.appText4') }}</span
            >
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div v-if="sptype == '4'" class="return">
      <van-cell
        v-for="(value, key) in workflowlist2"
        :key="key"
        @click="jump3(value)"
        :title="value.applistmemo"
        label="被撤销"
        is-link
        :value="value.crdate"
      >
        <template #icon>
          <svg-icon
            slot="icon"
            :icon-class="'plat' + value.appiconid"
          ></svg-icon>
        </template>
      </van-cell>
    </div>
    <div v-else>
      <div class="applyCollapse" v-for="(v, k) in workflowlist" :key="k">
        <van-collapse v-model="v.open">
          <van-collapse-item name="a">
            <template #title>
              <div class="apply-collapse-text-box">
                <span class="apply-collapse-text">{{ v.formtype }}(</span>
                <span class="apply-collapse-text van-color-primary">{{
                  v.list.length
                }}</span>
                <span class="apply-collapse-text">)</span>
              </div>
            </template>
            <van-cell
              v-for="(value, key) in v.list"
              :key="key"
              @click="jump(value)"
              :title="value.appname"
              :label="
                `${
                  sptype == '1'
                    ? '待审批'
                    : sptype == '2'
                    ? '我发起了'
                    : sptype == '3'
                    ? '我审批了'
                    : ''
                }${value.cnt}条${value.appname}申请`
              "
              is-link
            >
              <template #icon>
                <svg-icon
                  slot="icon"
                  :icon-class="'plat' + value.appiconid"
                ></svg-icon>
              </template>
            </van-cell>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetWorkflowInfo,
  GetActionWorkflowZX,
  GetMyWorkflow,
  GetMyCheckWorkflow,
  GetReturnWorkflow
} from '@/api/wx.js'
import { setData, getData, delData } from '@/utils/index.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  data() {
    const sptype = this.$route.query.sptype
    return {
      sptype,
      userInfo,
      forcheckcount: '',
      V3url: './',
      workflowlist: [],
      workflowlist2: []
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[1].style.color =
        '#1989fa'
    }, 200)
  },
  methods: {
    async initData() {
      // 审批流列表
      await this.getWorkflowInfo().then(res => {})
      switch (this.sptype) {
        case '1':
          // 待我审批
          await this._GetActionWorkflowZX().then(res => {})
          break
        case '2':
          // 我发起的
          await this._GetMyWorkflow().then(res => {})
          break
        case '3':
          // 我已审批
          await this._GetMyCheckWorkflow().then(res => {})
          break
        case '4':
          // 被撤销的
          await this._GetReturnWorkflow().then(res => {})
          break
      }
    },
    async getWorkflowInfo() {
      await GetWorkflowInfo({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        this.forcheckcount = res.forcheckcount[0].cnt || 0
      })
    },
    async _GetActionWorkflowZX() {
      await GetActionWorkflowZX({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        this.workflowlist = []
        let arr = [],
          formtype = null
        res.data.forEach((e, i) => {
          if (e.formtype != formtype) {
            formtype = e.formtype
            let obj = {
              formtype,
              open: ['a'], //  i == 0 ? ['a'] : [],
              list: []
            }
            obj.list = res.data.filter(item => item.formtype === formtype)
            arr.push(obj)
          }
        })
        this.workflowlist = arr
      })
    },
    async _GetMyWorkflow() {
      await GetMyWorkflow({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        this.workflowlist = []
        let arr = [],
          formtype = null
        res.data.forEach((e, i) => {
          if (e.formtype != formtype) {
            formtype = e.formtype
            let obj = {
              formtype,
              open: ['a'], //  i == 0 ? ['a'] : [],
              list: []
            }
            obj.list = res.data.filter(item => item.formtype === formtype)
            arr.push(obj)
          }
        })
        this.workflowlist = arr
      })
    },
    async _GetMyCheckWorkflow() {
      await GetMyCheckWorkflow({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        this.workflowlist = []
        let arr = [],
          formtype = null
        res.data.forEach((e, i) => {
          if (e.formtype != formtype) {
            formtype = e.formtype
            let obj = {
              formtype,
              open: ['a'], //  i == 0 ? ['a'] : [],
              list: []
            }
            obj.list = res.data.filter(item => item.formtype === formtype)
            arr.push(obj)
          }
        })
        this.workflowlist = arr
      })
    },
    async _GetReturnWorkflow() {
      await GetReturnWorkflow({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid'),
        index: 1
      }).then(res => {
        this.workflowlist2 = res.data.datainfo
        console.log(this.workflowlist2)
      })
    },
    jump(v) {
      // var url = 'shengpiinfo.html'
      // var title = v.appname
      // setLocVal('hyrptparas', title + '|' + '' + url + '|' + '' + v.autoid)
      // let href = this.V3url + '/weixinlocal/queryrpt/' + url
      // this.$router.push({
      //   path: '/jump',
      //   query: { url: href, time: new Date().getTime() }
      // })
      var url =
        this.sptype == '1'
          ? 'dwsplist.html'
          : this.sptype == '2'
          ? 'wfqdlist.html'
          : this.sptype == '3'
          ? 'wysplist.html'
          : ''
      setLocVal('wfqdlist', v.formautoid)
      setLocVal('wfqdlisttitle', v.appname)
      let href = this.V3url + '/weixinlocal/queryrpt/' + url
      this.$router.push({
        path: '/jump',
        query: { url: href, time: new Date().getTime() }
      })
    },
    jump2(v, n) {
      var url = v + '.html'
      if (v == 'dwsp') url = 'dwspclass.html'
      let href = this.V3url + '/weixinlocal/queryrpt/' + url
      setLocVal('hyrptparas', n + '|' + '' + v + '|')
      this.$router.push({
        path: '/jump',
        query: { url: href, time: new Date().getTime() }
      })
    },
    jump3(v) {
      var url = 'wfqdinfo.html'
      setLocVal('wfadmin', 0)
      setLocVal('wfqdlist', v.formautoid)
      setLocVal('wfqdinfo', v.autoid)
      setLocVal(
        'wfqdinfomsg',
        v.appiconid + '|' + v.applistmemo + '|' + v.crdate
      )
      setLocVal('sptype', 4)
      let href = this.V3url + '/weixinlocal/queryrpt/' + url
      this.$router.push({
        path: '/jump',
        query: { url: href, time: new Date().getTime() }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.applyContainer {
  width: 100%;
  position: relative;
  padding-bottom: 142px;
}

.applyNavBar {
  width: 100%;
  background: #fff;
  padding: 16rpx 0;
  .van-grid-item {
    /deep/.van-grid-item__content {
      .van-grid-item__text {
        height: 64px;
        line-height: 1.1;
        /* max-width: 5em; */
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}

.applyCollapse {
  width: 100%;
  background: #fff;
  margin-top: 30rpx;
  padding: 16rpx 0;
  .van-collapse {
    .van-collapse-item {
      .van-cell {
        .svg-icon {
          width: 74px;
          height: 74px;
          margin-bottom: 12px;
          margin-right: 12px;
        }
      }
    }
  }
}
.return {
  .van-cell {
    .van-cell__title {
      flex: 1 1 calc(100% - 18em);
      .van-cell__label {
        color: #ff7717;
      }
    }
    .van-cell__value {
      text-align: center;
    }
    .svg-icon {
      width: 74px;
      height: 74px;
      margin-bottom: 12px;
      margin-right: 12px;
    }
  }
}

.apply-collapse-header {
  width: 100%;
  height: 56rpx;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
}

.apply-collapse-left {
  height: 56rpx;
  flex: 2;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  padding-left: 32rpx;
}

.apply-collapse-text {
  font-size: 36rpx;
  font-weight: 900;
}

.apply-collapse-right {
  height: 56rpx;
  flex: 1;
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center !important;
  padding-right: 24rpx;
}

.apply-collapse-body {
  width: 100%;
  padding: 10rpx 32rpx;
}
</style>
